import {getResidentDocumentData} from '@/services/residents.service';

const state = {
  contractData: null
}
const getters = {
}
const actions = {
  getContractData: async ({commit}, id) => {
    try {
      const { data: { document } } = await getResidentDocumentData(id);
      commit('setContractData', document)
    } catch (e) {
      alert(e)
      commit('setContractData', null)
    }
  },
  resetContractData: ({ commit }) => {
    commit('setContractData', null)
  }
}
const mutations = {
  setContractData(state, contract) {
    state.contractData = contract;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
