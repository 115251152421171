import http from '@/plugins/http'
import {getConfigByParam} from '@/utils/config';

const BASE_PATH = getConfigByParam('BASE_API_PATH')

const getHouseResidents = () => http.get(`${BASE_PATH}/residents`);
const getPlans = () => http.get(`${BASE_PATH}/plans`);
const getResidentRelations = () => http.get(`${BASE_PATH}/residents-relations`);
const getResidentStatuses = () => http.get(`${BASE_PATH}/residents-status`);
const getSocketsList = (id) => http.get(`${BASE_PATH}/dashboard/sockets/${id}`);
const getResidentData = (id) => http.get(`${BASE_PATH}/residents/${id}`);
const getResidentDocumentData = (id) => http.get(`${BASE_PATH}/residents/${id}/get-document-data`);
const createResident = (data) => http.post(`${BASE_PATH}/residents`, data);
const editResident = (data, id) => http.put(`${BASE_PATH}/residents/${id}`, data);
const deleteResident = (id) => http.delete(`${BASE_PATH}/residents/${id}`);
const createResidentContact = (data) => http.post(`${BASE_PATH}/residents-contact`, data);
const editResidentContact = (data, id) => http.put(`${BASE_PATH}/residents-contact/${id}`, data);

export {
    getHouseResidents,
    getPlans,
    getResidentStatuses,
    getSocketsList,
    createResident,
    editResident,
    deleteResident,
    getResidentData,
    createResidentContact,
    editResidentContact,
    getResidentRelations,
    getResidentDocumentData
}
