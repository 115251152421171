import Vue from 'vue'
import Vuex from 'vuex'
import menu from './modules/menu.module'
import user from './modules/user.module'
import house from './modules/house.module'
import residents from './modules/residents.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    menu,
    user,
    house,
    residents
  }
})
