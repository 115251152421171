import {getConfig} from '@/services/config.service';

const state = {
  links: []
};
const getters = {
  getLinks() {
    return state.links
  }
};
const actions = {
  async getAllConfig({commit}) {
    try {
      const {menu, user} = await getConfig()
      commit('user/setUser', {...user, isAuth: true}, {root: true})
      commit('setMenus', menu)
    } catch (e) {
      console.log(e)
    }
  }
};
const mutations = {
  setMenus(state, menu) {
    state.links = menu
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
