<template>
  <main>
    <component :is="resolveLayout" id="main">
      <router-view/>
    </component>
    <contract-p-d-f id="print"></contract-p-d-f>
  </main>
</template>

<script>
import './App.scss'
import LayoutBlank from '@/layouts/BlankComponent.vue'
import LayoutContent from '@/layouts/ContentComponent.vue'
import ContractPDF from '@/components/ContractPDF.vue';

export default {
  components: {
    ContractPDF,
    LayoutBlank,
    LayoutContent
  },
  computed: {
    resolveLayout() {
      const {layout} = this.$route.meta;

      return `layout-${layout || 'blank'}`
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
