<template>
    <v-app>
<!--        <v-app-bar-->
<!--                app-->
<!--                flat-->
<!--                absolute-->
<!--        >-->
<!--            <v-icon class="pointer px-6" v-if="!drawer" @click="drawer = !drawer">{{ mdiViewHeadline }}</v-icon>-->
<!--        </v-app-bar>-->
        <v-navigation-drawer
                v-model="drawer"
                app
        >
            <v-sheet
                    class="app-color pa-4"
            >
                <div class="text-center">
                    <img :src="require('../assets/Logo-01.png')" alt="GrandHouse" class="logo">
                </div>
                <div class="text-center caption">{{ name }}</div>
                <div class="text-center">{{ email }}</div>
            </v-sheet>

            <v-divider></v-divider>

            <v-list>
                <v-list-item
                        v-for="(text, link) in links"
                        :key="text"
                        :to="`/${link}`"
                        link
                >
                    <!--          <v-list-item-icon>-->
                    <!--            <v-icon>{{ link.icon }}</v-icon>-->
                    <!--          </v-list-item-icon>-->

                    <v-list-item-content>
                        <v-list-item-title>{{ text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <!--      <v-list>-->
            <!--        <v-list-item to="/login">-->
            <!--          <v-list-item-icon>-->
            <!--            <v-icon>-->
            <!--              {{ mdiLogin }}-->
            <!--            </v-icon>-->
            <!--          </v-list-item-icon>-->

            <!--          <v-list-item-content>-->
            <!--            <v-list-item-title>Login</v-list-item-title>-->
            <!--          </v-list-item-content>-->
            <!--        </v-list-item>-->
            <!--      </v-list>-->

        </v-navigation-drawer>

        <v-main class="main-background">
            <v-container
                    class="py-8 px-6"
                    fluid
            >
                <v-card
                        class="px-6"
                        elevation="4"
                >
                    <slot name="header"></slot>
                    <slot></slot>
                </v-card>
            </v-container>
        </v-main>

        <v-footer
                app
                inset
                color="transparent"
                absolute
                height="56"
                class="px-0"
        >
            <div class="boxed-container w-full">
                <div class="mx-6 d-flex justify-space-between">
                    <span>&copy; 2023</span>
                </div>
            </div>
        </v-footer>
    </v-app>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {mdiLogin, mdiViewHeadline} from '@mdi/js'

    export default {
        beforeRouteEnter(to, from, next) {
            next()
        },
        components: {},
        data() {
            return {
                mdiLogin,
                mdiViewHeadline,
                drawer: true
                // links: [
                //   {text: 'Home', icon: mdiViewDashboard, to: '/'},
                //   {text: 'About', icon: mdiForum, to: '/about'},
                //   {text: 'RequestManager', icon: mdiForum, to: '/request-manager'}
                // ]
            }
                ;
        },
        computed: {
            ...mapGetters('user', {
                name: 'getUserName',
                email: 'getUserEmail'
            }),
            ...mapGetters('menu', {
                links: 'getLinks'
            }),

        },
        mounted() {
            this.getConfig();
        },
        methods: {
            ...mapActions('menu', {
                getConfig: 'getAllConfig'
            })
        }
    };
</script>

<style lang="scss" scoped>
    .app-color {
        /*background-color: rgba(76, 175, 80, 0.5) !important;*/
        background-color: #00796B !important;
    }

    nav {
        background-color: #37463D;
        /*background: {*/
            /*image: url("../assets/login.jpg");*/
            /*size: cover;*/
            /*position: right 100%;*/
        /*}*/
    }

    .main-background {
        background-color: #f5f5f5;
        /*background-color: #37463D;*/
    }

    .v-list-item.v-list-item--link {
        &.v-list-item--active {
            background-color: rgba(255, 255, 255, .1);
        }

        &:hover {
            background-color: rgba(255, 255, 255, .15);
        }
    }

    .v-list-item__content {
        text-align: left;
        color: #ffffff;
    }

    .v-app-bar ::v-deep {
        .v-toolbar__content {
            padding: 0;

            .app-bar-search {
                .v-input__slot {
                    padding-left: 18px;
                }
            }
        }
    }

    .boxed-container {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }
</style>
