<template>
    <print-layout>
      <template v-if="contract">
        <h1 class="text-center-pdf">ДОГОВІР № {{contract.resident_id}}</h1>
        <p class="text-center-pdf">про надання платних послуг з підтриманого проживання осіб похилого віку та осіб з
            інвалідністю</p>
        <div class="row text-between">
            <div>м.Дніпро</div>
            <div>«____»______________2023р.</div>
        </div>

        <b>ВИКОНАВЕЦЬ : Фізична особа-підприємець Доценко Ольга Олександрівна</b>, що діє на підставі
        державної реєстрацій як підприємця в Єдиному державному реєстрі юридичних осіб,
        фізичних осіб – підприємців та громадських формувань з одного боку, та
        <br>

        <b>ЗАМОВНИК : {{contract.name}}</b>,
        паспорт <b>{{contract.documents}}</b>, <br>
        виданий <b>{{contract.issued}}</b> <br>
        Реєстраційний номер облікової картки платника податків <b>{{contract.inn}}</b>, з іншого боку,
        іменовані надалі як СТОРОНИ, а кожна окремо – СТОРОНА, уклали даний Договір про
        наступне:<br>
        <h3 class="text-center-pdf"> 1.ПРЕДМЕТ ДОГОВОРУ </h3>
        1.1. За цим Договором Виконавець зобов’язується надати ЗАМОВНИКУ та/або його членам
        родини наступні послуги: підтримане проживання осіб похилого віку та осіб з інвалідністю
        (код соціальної послуги 009.1 згідно Класифікатора соціальних послуг, затвердженого
        Наказом міністерства соціальної політики України від 23.06.2020р.No429), а Замовник
        зобов’язується сплатити ці послуги відповідно до умов чинного Договору.<br>
        1.2. Житловим приміщенням є окремо відведена площа приміщення розміром ____м2 в
        кімнаті площею ____м 2., ліжко кількістю (1) шт. (інші меблі на розсуд виконавця), що
        знаходяться за адресою: м. Дніпро, вул. Леонтовича, 27А.
        Номер кімнати, чи її розташування у будинку, а також місце в кімнаті надається на розсуд
        ВИКОНАВЦЯ, який має право самостійно змінювати її за час проживання ЗАМОВНИКА,
        та/або членів його родини у зв’язку з господарськими потребами, у тому числі у зв’язку з
        ремонтом приміщення, без порушення розміру зазначеної в даному пункті Договору площі
        кімнати.<br>
        1.3. Члени родини ЗАМОВНИКА які мають право тимчасово проживати разом з
        ЗАМОВНИКОМ, або окремо від нього за адресою вказаною у пункті 1.2 Договору:<br>
        1). <b>{{contract.resident_name}}</b><br>
        1.4. У разі тимчасового мешкання члена, або членів родини ЗАМОВНИКА окремо від
        ЗАМОВНИКА за вказаною адресою, додаткова угода (Додаток1) з вказаними членами родини
        ЗАМОВНИКА не укладається. Вся відповідальність за правильність наданої інформації при
        складанні цього Договору та інших документів передбачених умовами Договору, дій членів
        родини ЗАМОВНИКА перелік яких зазначений у пункті 1.3 Договору та бажання цих членів
        родини мешкати за вказаною адресою, покладається на ЗАМОВНИКА.<br>
        1.5. ВИКОНАВЕЦЬ надає в безкоштовне користування ЗАМОВНИКУ або члену його родини
        пластикові бокси для зберігання ліків та інших медичних препаратів/виробів ЗАМОВНИКА в
        окремій кімнаті.<br><br>

        <div class="footer row text-between">
            <div>Виконавець_______________</div>
            <div>Замовник__________________</div>
        </div>

        <div class="pagebreak"></div>


        <h3 class="text-center-pdf">2 ПРАВА ТА ОБОВ'ЯЗКИ СТОРІН.</h3>

        2.1. ВИКОНАВЕЦЬ зобов’язаний:
        2.1.1. надавати необхідну і достовірну інформацію про хід виконання послуг їх вартість, про
        порядок і терміни оплати послуг та інші пов’язані із даним Договором відомості на вимогу
        ЗАМОВНИКА;
        2.1.2. забезпечення проживання особи або групи осіб в приміщенні, яке знаходиться в
        належному санітарно-технічному стані;
        2.1.3. допомога в організації розпорядку дня;
        2.1.4. допомога в організації взаємодії з іншими фахівцями та службами;
        2.1.5. здійснювати нагляд за станом здоров’я ЗАМОВНИКА та/або членів його родини
        кожного дня:
        - здійснювати контроль за своєчасним прийманням ЗАМОВНИКОМ та/або членами його
        родини лікарських засобів, призначених лікарем;
        - вимірювати температуру тіла та артеріального тиску за потребую або за проханням
        ЗАМОВНИКА та/або члена його родини чи за призначенням лікарем графіком;
        - змінювати положення тіла для запобігання виникнення попрілостей та пролежнів на
        вимогу ЗАМОВНИКА або членів його родини, а також їх профілактика.
        2.1.6. допомагати в проведенні гігієнічних процедур;
        2.1.7. допомагати при прийманні їжі;
        2.1.8. здійснювати прибирання приміщення де тимчасово мешкає ЗАМОВНИК та/або члени
        його родини;
        2.1.9. здійснювати провітрювання, кварцування приміщення де тимчасово мешкає
        ЗАМОВНИК та/або члени його родини;
        2.1.10. Супровід на прогулянку (якщо дозволяє фізичний або психічний стан).
        2.2. ВИКОНАВЕЦЬ має право:
        2.2.1. відмовити в прийнятті замовлення на розміщення та утримання у випадку відсутності
        вільних місць та/або в разі наявності заборгованості за попередньо надані послуги;
        2.2.2. вимагати від ЗАМОВНИКА своєчасної плати за надання послуг, передбачених в п.3.1
        даного Договору, а також погашення заборгованості у випадку її утворення;
        2.2.3. розірвати даний Договір у випадках невнесення плати за надання послуг, передбачених
        п.3.1. даного Договору;
        2.2.4. інформувати ЗАМОВНИКА про стан надання послуг розміщення та утримання у
        житловому приміщенні для тимчасового проживання;
        2.2.5. розірвати даний Договір в односторонньому порядку направивши за адресою
        місцезнаходження ЗАМОВНИКА письмове повідомлення в наступних випадках:
        - виявлення у ЗАМОВНИКА стану здоров’я (хвороба психічна, інфекційна тощо) та/або
        обставин (особливості поведінки ЗАМОВНИКА тощо), або членів його родини, зазначених в
        пункті 1.3 даного Договору, які унеможливлюють подальше надання послуг з утримання у
        житловому приміщенні для тимчасового проживання та які були приховані ЗАМОВНИКОМ на
        момент укладення цього Договору;
        - у разі пошкодження або знищення майна ВИКОНАВЦЯ;
        - отримання ВИКОНАВЦЕМ погроз з боку ЗАМОВНИКА, та/або членів його родини
        щодо заподіяння шкоди здоров’ю, життю, або знищення майна ВИКОНАВЦЯ;
        - невиконання умов пункту 2.3.2. Договору на строк, що перевищує один календарний
        місяць.
        Підстави для розірвання даного Договору з ініціативи ВИКОНАВЦЯ не є вичерпними.
        Договір також може бути розірваним в односторонньому з боку ВИКОНАВЦЯ у разі відсутності потреби в подальшому
        наданні послуг ЗАМОВНИКУ чи/або членам його родини,
        або бажання у ВИКОНАВЦЯ надавати послуги, а також прийняття рішення щодо припинення
        підприємницької діяльності.

        <div class="pagebreak"></div>


        При виникненні умов, зазначених у цьому пункті Договору, ВИКОНАВЕЦЬ направляє
        письмове повідомлення на адресу ЗАМОВНИКА вказану у Договорі або іншим засобом
        зв’язку з актом приймання передачі наданих послуг. В повідомленні ВИКОНАВЕЦЬ вказує
        дату розірвання Договору. Вказані документи можуть бути вручені ЗАМОВНИКУ особисто
        ВИКОНАВЦЕМ. Отримавши вказане повідомлення та акт ЗАМОВНИК зобов’язаний у
        продовж трьох календарних днів повернути на адресу ВИКОНАВЦЯ підписаний другий
        примірник акту приймання-передачі наданих послуг та сплатити ВИКОНАВЦЮ в трьох
        денний строк вартість наданих послуг за цим Договором.
        У разі не повернення ЗАМОВНИКОМ другого примірника акту приймання-передачі
        наданих послуг ВИКОНАВЦЮ, відсутності письмових заперечень (претензіях) з боку
        ВИКОНАВЦЯ у продовж 10 (десяти) календарних днів з дати направлення ВИКОНАВЦЕМ,
        послуги вказані в листі вважаються наданими своєчасно і в повному обсязі, вартість послуг
        вважається погодженою між сторонами Договору.
        Претензії від ЗАМОВНИКА, які надійшли після спливу строку визначеного Сторонами у
        цьому пункті Договору не розглядається та не підлягають задоволенню.
        Будь які документи, направлені ВИКОНАВЦЕМ за адресою ЗАМОВНИКА, зазначеною в
        даному Договорі вважаються отриманими ЗАМОВНИКОМ. Відповідальність за своєчасне
        отримання відправлень покладається на ЗАМОВНИКА.
        2.3. ЗАМОВНИК зобов’язаний:
        2.3.1. під час укладання Договору надати достовірну інформацію про стан свого здоров’я
        (хвороба психічна, інфекційна тощо) та/або членів своєї родини та інші обставини
        (особливості поведінки, тощо які можуть вплинути на можливість або якість надання послуг
        ВИКОНАВЦЕМ.
        2.3.2. своєчасно вносити оплату за послуги ВИКОНАВЦЯ на умовах визначених у цьому
        Договорі;
        2.3.3. самостійно сплачувати вартість медичного обслуговування які надаються ЗАМОВНИКУ
        та/або членам його родини, вартість ліків та призначених процедур лікарем;
        2.3.4. мати при собі належну медичну документацію з інформацією щодо стану здоров’я його
        та/або членів його родини;
        2.3.5. по медичним показникам самостійно викликати лікаря або ШМД, або влаштуватися у
        медичний заклад;
        2.3.6. на повідомлення ВИКОНАВЦЯ про погіршення стану здоров’я ЗАМОВНИКА або члена
        його родини надати можливість та згоду ВИКОНАВЦЮ на здійснення невідкладних заходів з
        надання допомоги ЗАМОВНИКУ або члену його родини;
        2.3.7. мати особисті речі, які необхідні для звичайного образу життя (одяг, речі першої
        необхідності, ліки, засоби гігієни, тощо);
        2.3.8. протягом 5-ти (п’яти) днів відшкодувати заподіяні ним та/або членом родини збитки у
        результаті псування приміщень та іншого майна ВИКОНАВЦЯ при користуванні послугами
        ВИКОНАВЦЯ;
        2.3.9. дбайливо ставитись до майна ВИКОНАВЦЯ, що знаходиться у приміщенні для
        тимчасового проживання;
        2.3.10. після закінчення терміну надання послуг або дострокового розірвання даного
        Договору звільнити житлове приміщення у строк що не перевищує дві доби.

        <div class="pagebreak"></div>
        2.4. ЗАМОВНИК має право:
        2.4.1. проживати самостійно або разом з членами своєї родини наймати для членів своєї
        родини приміщення у будинку за адресою вказаною у пункті 1.1. Договору, при цьому
        мешкати окремо від них за іншими адресами;
        2.4.2. користуватися послугами ВИКОНАВЦЯ у відповідності до положень даного Договору;
        2.4.3. вимагати своєчасного надання якісних послуг;
        2.4.4. замовити через ВИКОНАВЦЯ проведення свого та/або членів своєї родини медичного
        обстеження, чи огляд лікарем, при цьому право вибору лікаря та витрати щодо його приїзду
        покладаються на ЗАМОВНИКА;
        2.4.5. користуватися коридорами, санвузлами, побутовою технікою, столовими приборами,
        посудом, меблі ВИКОНАВЦЯ (якщо дозволяє фізичний чи психічний стан здоров’я);
        2.4.6. у будь який час розірвати Договір з ВИКОНАВЦЕМ підписавши додаткову угоду про
        розірвання цього Договору.
        <h3 class="text-center-pdf">3.ВАРТІСТЬ ПОСЛУГ ТА ПОРЯДОК РОЗРАХУНКІВ. </h3>
        3.1. ЗАМОВНИК щомісяця до «{{contract.register_day}}» числа вносить попередню оплату в розмірі 100 % від
        вартості послуг ВИКОНАВЦЯ.
        3.2. ЗАМОВНИК сплачує ВИКОНАВЦЮ попередню оплату за його послуги у розмірі {{contract.price}} грн. 00 копійок.
        ({{contract.price_string}} гривень 00 копійок) за місяць;
        3.3. У разі дострокового розірвання даного Договору до закінчення поточного місяця, в тому
        числі п.2.3.1. Договору, плата за послуги в розмірі зазначеному в п.3.2 даного Договору,
        підлягає сплаті у повному обсязі за поточний місяць і поверненню не підлягають. У
        виключних випадках на розсуд ВИКОНАВЦЯ за умови своєчасного отримання ВИКОНАВЦЕМ
        попередньої оплати за поточний місяць в повному обсязі при достроковому розірванні даного
        Договору, ВИКОНАВЕЦЬ має право повернути ЗАМОВНИКУ залишок невикористаних
        грошових коштів, який розраховується за формулою:
        А = Б-(С*659,00) де
        А – сума, що підлягає поверненню Замовнику;
        Б – сума своєчасно отриманих Виконавцем грошових коштів за повний поточний місяць;
        С – кількість днів, в які послуги були фактично надані;
        3.4. Оплата здійснюється у безготівковій формі шляхом переказу коштів на банківський
        рахунок ВИКОНАВЦЯ вказаний у Договорі, або в інший спосіб, не заборонений чинним
        законодавством України. В якості оплати за послуги ВИКОНАВЕЦЬ не приймає майно
        Замовника або членів його родини.
        3.5. Фактом, що підтверджує виконання умов договору в повному обсязі є відповідний АКТ
        приймання-передачі (Додаток 2) послуг, який є невід’ємною частиною цього Договору.
        3.6. Після надання послуг, ВИКОНАВЕЦЬ складає в письмовій формі та надає ЗАМОВНИКУ
        для підписання Акт приймання-передачі послуг (у двох примірника). При відсутності
        зауважень ЗАМОВНИК протягом 3 (трьох) календарних днів з моменту отримання Акту,
        підписує його та один з примірників Акту повертає ВИКОНАВЦЮ. Якщо у встановлений
        договором строк (з додатковим урахуванням поштового перебігу) ВИКОНАВЕЦЬ не отримує
        підписаний ЗАМОВНИКОМ Акт або мотивовану відмову від його підписання, надані послуги
        вважаються прийнятими в повному обсязі, з дотриманням усіх умов Договору.
        3.7. Акт приймання-передачі послуг підписується ЗАМОВНИКОМ, та підтверджує приймання
        послуг ЗАМОВНИКОМ в повному обсязі.

        <div class="pagebreak"></div>


        3.8. Сторони домовилися, що після підписання Акту приймання-передачі послуг
        ЗАМОВНИКОМ та відсутності зауважень до його підписання, претензії з боку ЗАМОВНИКА
        щодо неналежного надання послуг ВИКОНАВЦЕМ в подальшому не приймаються.
        3.9. ЗАМОВНИК має право сплатити послуги ВИКОНАВЦЯ в порядку попередньої оплати за
        наступні місяці в узгодженому з ВИКОНАВЦЕМ розмірі.
        <h3 class="text-center-pdf">4.ВІДПОВІДАЛЬНІСТЬ СТОРІН. </h3>
        4.1. СТОРОНИ несуть відповідальність за виконання або неналежне виконання умов цього
        Договору у відповідності до вимог чинного законодавства України;
        4.2. ВИКОНАВЕЦЬ не несе відповідальність за погіршення стану здоров’я ЗАМОВНИКА або
        членів його родини ЗАМОВНИКА пов’язані з:
        - прогресуванням патологічного процесу;
        - відновлення застарілого захворювання на фоні діючого;
        - з виникненням супутнього захворювання на фоні діючого;
        - а так само з діями самого ЗАМОВНИКА та/або члена його родини або третіх осіб
        цілодобово;
        4.3. ВИКОНАВЕЦЬ не зобов’язаний знаходитися біля ЗАМОВНИКА або членів його родини
        цілодобово;
        4.4. ЗАМОВНИК несе повну фінансову та кримінальну відповідальність у разі приховування
        ним достовірної інформації про стан свого здоров’я (психічна хвороба, інфекційна тощо) чи
        членів своєї родини, приховування інших обставин(особливості поведінки, тощо) , що можуть
        нести загрозу здоров’ю чи життю інших ЗАМОВНИКІВ чи членів їх родини;
        4.5. ЗАМОВНИК несе повну фінансову відповідальність за нанесення ним або членами його
        родини матеріальних збитків(пошкодження майна)ВИКОНАВЦЯ;
        4.6. За порушення строків оплати, зазначених в п.3.2. цього Договору, ЗАМОВНИК сплачує
        ВИКОНАВЦЮ пеню у розмірі подвійної облікової ставки НБУ, що діяла момент прострочення,
        від суми заборгованості за кожний день прострочення.
        4.7. У випадку дострокового розірвання Договору з будь яких підстав, закінчення терміну дії
        Договору, не звільнення ЗАМОВНИКОМ або членами його родини приміщень, ЗАМОВНИК
        сплачує подвійну вартість послуг які будуть надаватися ЗАМОВНИКУ, або членам його
        родини перелік яких зазначений у пункті 1.3. Договору, до моменту фактичного звільнення
        приміщення.
        4.8. СТОРОНИ звільняються від відповідальності за часткове або повне невиконання чи
        неналежне виконання своїх обов’язків за Договором, якщо таке невиконання стало наслідком
        дії непереборної сили, передбачені ЗУ «Про торгово-промислові палати України», які виникли
        незалежно від волі СТОРІН після укладання Договору, які перешкоджають повному або
        частковому виконанню Договору.
        Перед звільненням СТОРОНИ від відповідальності визначається терміном дії
        зазначених обставин. Для посилання на вказані обставини будь – яка Сторона зобов’язана
        підтвердити їх у Торгово – промисловій палаті України. У випадку виникнення обставин
        непереборної сили, Сторона, яка знаходиться під їх дією, повідомляє про це іншу Сторону
        протягом 5 (п’яти) календарних днів з моменту виникнення таких обставин. У випадку
        порушення Стороною, яка знаходиться під дією обставин непереборної сили зазначених
        вимог. Вона втрачає право посилатись на такі обставини як на підставу для звільнення від
        відповідальності.

        <div class="pagebreak"></div>

        <h3 class="text-center-pdf">5. ТЕРМІН ДІЇ ДОГОВОРУ. </h3>
        5.1. Даний Договір набирає чинності з моменту підписання і діє до «__»__________20___р.
        5.2. Кожна із СТОРІН має право розірвати цей Договір, попередньо письмово попередив про
        це іншу СТОРОНУ не менш ніж за 5 (п’ять) календарних днів. В цьому випадку СТОРОНИ
        укладають додаткову угоду про розірвання Договору. Дата складання додаткової угоди є
        датою закінчення терміну дії Договору.
        5.3. У разі виникнення умов при яких Сторонам надається право розірвати Договір в
        односторонньому порядку, СТОРОНИ повинні діяти відповідно до умов зазначених у цьому
        Договорі, без складання додаткової угоди.
        5.4. Даний Договір після закінчення строку вказаному у пункті 5.1. Договору автоматично
        пролонгується на такий же термін, в разі якщо ЗАМОВНИК не направить ВИКОНАВЦЮ
        письмове повідомлення про його розірвання не менше ніж за 10 днів до моменту його
        припинення. Кількість автоматичних пролонгацій не обмежена.
        <h3 class="text-center-pdf">6.ІНШІ УМОВИ.</h3>
        6.1. Цей договір складений українською мовою в двох примірниках, які мають однакову
        юридичну силу, по одному для кожної із СТОРІН.
        6.2. Будь-які зміни і доповнення до даного Договору, дійсні тільки у випадку, якщо вони
        виконані в письмовій формі, підписані обома СТОРОНАМИ. Належним чином оформлені
        зміни та доповнення до даного Договору є його невід’ємною частиною.
        6.3. Всі спірні питання вирішуються шляхом переговорів СТОРІН. У разі, якщо СТОРОНИ не
        можуть дійти згоди, то всі спори та розбіжності підлягають вирішенню у відповідності до
        чинного законодавства України.
        6.4. СТОРОНИ гарантують про що:
        6.4.1. на момент укладення цього Договору вони не пов’язані будь яким зобов’язанням, яке
        може тим чи іншим чином перешкодити повному або частковому виконанню всіх умов
        Договору;
        6.4.2. їх невідомі будь-які інші обставини, які перешкоджають кожній зі Сторін укласти даний
        Договір і належним чином виконати всі зобов’язання за ним;
        6.4.3. в повному обсязі будуть дотримуватись і виконувати умови цього Договору та
        самостійно нестимуть ризик невиконання чи неналежного виконання Договору;
        6.4.4. не мають наміру вчинити будь які дії, які можуть призвести до порушення прав і/чи
        нанесення шкоди честі, гідності, ділової репутації іншої Сторони та/або третіх осіб;
        6.4.5. укладення даного Договору і його виконання не суперечать чинному законодавству
        України, міжнародним нормам чи звичаям ділового обороту;
        6.4.6. Замовник підтверджує, що предмет та зміст даного договору відповідає його волі та є
        зрозумілим, а також суть термінів, що вживаються у Договорі;
        6.4.7. при укладенні Договору відсутній будь-який обман чи інше приховування фактів, які
        можуть мати істотне значення для виконання даного Договору та були приховані ними;
        6.4.8. цей Договір укладається у відповідності з їх справжньою волею, без будь якого
        застосування фізичного чи психологічного тиску і відповідає реальній домовленості між ними;
        6.4.9. при укладенні цього договору та визначенні його умов між ними немає зловмисної
        домовленості;
        6.4.10. даний Договір укладається на вигідних для них умовах і не є результатом впливу
        тяжких обставин на будь яку із Сторін;


        <div class="pagebreak"></div>

        6.4.11. правочин вчиняється з наміром створення відповідних правових наслідків (не є
        фіктивним) і не приховує інший правочин (не є удаваним).
        6.4.12. Замовник підтверджує, що Він в дієздатності не обмежений, під опікою та піклуванням
        не перебуває, не знаходиться в алкогольному, наркотичному або токсичному стані.
        6.5. У випадках, не передбачених цим Договором, Сторони керуються нормами чинного
        законодавства України.
        6.6.
        ЗАМОВНИК добровільно надає свою безумовну згоду на обробку будь-яких
        персональних даних, які стали відомими в результаті виконання цього Договору. Обробка
        включає, але не обмежується, збиранням, реєстрацією, зберіганням, адаптацією,
        оновленням, використанням, поширенням та знищенням персональних даних, необхідних
        для належного виконання договірних зобов’язань.
        ЗАМОВНИК зобов’язується при зміні своїх персональних даних, негайно повідомити
        ВИКОНАВЦЯ про це, надаючи, у разі необхідності, відповідні документи.
        Права ЗАМОВНИКА регламентуються ст.8 Закону України «Про захист персональних
        даних».
        Підпис ЗАМОВНИКА на цьому Договорі означає однозначну згоду з вищевикладеним і є
        підтвердженням того, що ЗАМОВНИК ознайомлений зі змістом ст. 8,10 Закону України «Про
        захист персональних даних».

        <br>
        <h3 class="text-center-pdf">7. АДРЕСИ ТА РЕКВІЗИТИ СТОРІН.</h3>
        <br>
        <div class="row">
            <div class="col-6">
                <b>ВИКОНАВЕЦЬ:</b><br>
                <b>Фізична особа-підприємець</b><br>
                <b>Доценко Ольга Олексадрівна</b><br>
                IBAN: UA523220010000026006330014389<br>
                Реєстраційний номер облікової картки
                платника податків: 2306714009<br>
                Акціонерне товариство:<br>
                УНІВЕРСАЛ БАНК <br>
                МФО:322001<br>
                ОКПО Банку:21133352<br>
                Тел.: _________________<br>
            </div>
            <div class="col-6">
                <b>ЗАМОВНИК:</b><br>
                <b>П.І.Б. {{contract.name}}</b><br>
                Паспорт {{contract.documents}}<br>
                Виданий {{contract.issued}}<br>
                Зареєстрований за адресою <br>
                {{contract.address}}<br>
                Реєстраційний номер облікової картки
                платника податків: {{contract.inn}}<br>
                Тел.: {{contract.phone}}<br>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <br>
                <br>
                <br>
                <div>_________________ / ФОП Доценко О.О.</div>
            </div>
            <div class="col-6">
                <br>
                <br>
                <br>
                <div>_________________ / _____________</div>
            </div>
        </div>
      </template>
    </print-layout>
</template>

<script>
import store from '@/store';

export default {
  name: 'ContractPDF',
  beforeRouteEnter(to, from, next) {
    if (store.state.residents.contractData) {
      next();
    } else {
      next({name: 'ResidentsPage'});
    }
  },
  computed: {
    contract() {
      return this.$store.state.residents.contractData;
    }
  },
  created() {
    window.addEventListener('afterprint', () => {
      setTimeout(() => this.$store.dispatch('residents/resetContractData'), 500);
    });
  },
  watch: {
    contract(n) {
      if (n) {
        setTimeout(() => window.print(), 350);
      }
    }
  }
};
</script>
