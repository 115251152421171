import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeComponent.vue'
import store from '../store'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/home/HomePage.vue'),
        meta: {
          layout: 'content'
        }
      }
    ]
  },
  {
    path: '/components',
    component: () => import('../components/ExampleUsageComponents.vue')
  },
  {
    path: '/houses',
    name: 'Houses',
    component: () => import('../views/houses/HousesComponent.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/houses/create',
    name: 'CreateHouse',
    component: () => import('../views/houses/CreateHouse.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/sockets',
    name: 'Sockets',
    component: () => import('../views/sockets/SocketsComponent.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/sockets/create',
    name: 'CreateSocket',
    component: () => import('../views/sockets/CreateSocket.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/sockets/edit',
    name: 'EditSocket',
    component: () => import('../views/sockets/EditSocket.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/residents',
    name: 'ResidentsPage',
    component: () => import('../views/residents/ResidentsPage.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/resident-info',
    name: 'FullResidentInfo',
    component: () => import('../views/residents/FullResidentInfo.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/residents/create',
    name: 'CreateResident',
    component: () => import('../views/residents/CreateResident.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/residents/edit',
    name: 'EditResident',
    component: () => import('../views/residents/EditResident.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/RegisterComponent.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/request-manager',
    name: 'RequestManager',
    component: () => import('../views/RequestManager.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginComponent.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/payments',
    name: 'PaymentsPage',
    component: () => import('../views/payments/PaymentsComponent.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/employees',
    name: 'EmployeesPage',
    component: () => import('../views/employees/EmployeesPage.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/schedule',
    name: 'SchedulePage',
    component: () => import('../views/schedule/SchedulePage.vue'),
    meta: {
      layout: 'content'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const {isAuth} = store.state.user
  const controller = to.fullPath.split('/')[1]
  if (isAuth || controller === 'login') {
    next()
  } else {
    next({name: 'Login'})
  }
});

export default router
