import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#009688',
        secondary: '#4caf50',
        accent: '#8bc34a',
        error: '#e91e63',
        warning: '#ffc107',
        info: '#00bcd4',
        success: '#4caf50'
      },
    },
  },
});
