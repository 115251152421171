import {getConfig} from "../../services/config.service";

const state = {
  isAuth: false,
  name: '',
  email: ''
}
const getters = {
  getIsAuth() {
    return state.isAuth
  },
  getUserEmail() {
    return state.email
  },
  getUserName() {
    return state.name
  }
}
const actions = {
  async getConfig() {
    await getConfig()
  }
}
const mutations = {
  setUser(state, {name, email, isAuth}) {
    state.isAuth = isAuth
    state.name = name
    state.email = email
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
