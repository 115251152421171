import http from '@/plugins/http'
import {getConfigByParam} from '@/utils/config';

const BASE_PATH = getConfigByParam('BASE_API_PATH');

const getHouseList = () => http.get(`${BASE_PATH}/houses`);
const getHouse = (id) => http.get(`${BASE_PATH}/houses/${id}`);
const createHouse = (data) => http.post(`${BASE_PATH}/houses`, data);
const updateHouse = (id, data) => http.put(`${BASE_PATH}/houses/${id}`, data);

export {
  getHouseList,
  getHouse,
  createHouse,
  updateHouse
}
