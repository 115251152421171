import {createHouse} from '@/services/house.service';

const state = {
}
const getters = {
}
const actions = {
  createHouse: async (state, house) => {
    try {
      await createHouse(house)
        .then(res => console.log(res))
    } catch (e) {
      alert(e)
    }
  }
}
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
