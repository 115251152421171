<template>
    <div>
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'PrintLayout'
    }
</script>

<style>
    @media screen {
        .footer {
            display: none;
        }
    }

    @media print {
        * {
            font-family: "Times New Roman", serif;
            font-size: 12pt;
        }

        .footer {
            position: fixed;
            bottom: 0;
        }

        .pagebreak {
            clear: both;
            page-break-after: always;
        }

        .text-center-pdf {
            text-align: center !important;
            color: black;
        }

        .text-between {
            width: 100%;
            justify-content: space-between;
            margin: 20px;
        }
    }

    @page {
        /*size:  auto;   !* auto is the initial value *!*/
        size: A4;
        height: 90%;
        margin: 15mm 10mm 15mm 10mm;   /* this affects the margin in the printer settings */
        padding-top: 20mm;
        padding-bottom: 20mm;
        /*margin-top: 20px;*/
    }

    html {
        background-color: #FFFFFF;
        margin: 25px; /* this affects the margin on the html before sending to printer */
    }

    body {
        margin: 0px; /* margin you want for the content */
    }
</style>
