import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import './plugins/components'
import './plugins/v-mask'

Vue.config.productionTip = false

const bootstrap = async () => {
  try {
    await store.dispatch('menu/getAllConfig');
  } finally {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
    }).$mount('#app');
  }
};

bootstrap();
