import axios from 'axios'
import router from '../router'

const http = axios.create()

http.defaults.baseURL = '/';

http.interceptors.request.use(
  request => {
    request.headers.Accept = 'application/json'
    request.headers['Content-Type'] = 'application/json'
    return request
  }
);

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response.data?.message === 'Unauthenticated.') {
      router.push({name: 'Login'});
    }
    return Promise.reject(error);
  }
);

export default http
